import {
  BridgingInsurance, CreditedInitialCosts, InitialCosts,
  InsurancePackage,
  LifeInsurance, MortgageXSellType, Offer,
  PaymentInsurance, Period,
  RealEstateInsurance
} from '@/models/simulation/Simulation';
import {numeralFormat} from '@/filters/StringFilters';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {EMPTY_VALUE} from '@/components/calculator/results/tableElements/TableElementsConstants';
import {inRange} from 'lodash-es';
import EnvironmentService from '@/env/EnvironmentService';
import {appDomainConfig} from '@/env/AppDomain';
import {i18nFormatter} from '@/services/StringFormatter';

export type InsuranceType = BridgingInsurance | RealEstateInsurance |
  LifeInsurance | InsurancePackage | PaymentInsurance;

const isInRange = (value: number = 0, min: number = 0, max: number = 0): boolean =>
  // inRange is not inclusive, so we need to check if value is equal to min or max
  inRange(value, min, max) || value === max || value === min;

const isInBridgingInsurancePeriod = (x: Offer, xSell?: MortgageXSellType): boolean => {
  if (!xSell) {
    return false;
  }
  const {bridgingInsurance,} = x.monthlyFees;
  const period: Period | undefined = bridgingInsurance?.[0].period;
  const [insurance,] = x.monthlyFees?.[xSell] ?? [];
  const isPeriodProvided: boolean = Boolean(insurance?.period && period);
  const isInBridgingInsurancePeriod: boolean = isInRange(insurance?.period?.from, period?.from, period?.until);
  return !isPeriodProvided || isInBridgingInsurancePeriod;
};

const getCreditedInsurance = (offer: Offer, xSell: MortgageXSellType): Nullable<InsuranceType> =>
  offer.creditedInitialCosts?.[xSell] ?? null;

const renderCreditedInsuranceDetails = (insurance: InsuranceType): string =>
  `${I18NGetter().useHeaderContent.CREDITED_INITIAL_COST} / ${numeralFormat(insurance?.fee)} ${EnvironmentService.Environment.currentCurrency()} (${insurance?.coveredPeriodDescription})`;

export const renderInsurance = (
  offer: Offer,
  xSell: MortgageXSellType,
  loanPeriodInMonths?: Nullable<number>
): string => {
  const insurances = offer.monthlyFees[xSell];
  if (Array.isArray(insurances)) {
    const [insurance,] = insurances;
    const fee: string = numeralFormat(insurance.fee);
    const periodFrom: number = insurance.period?.from ?? 0;
    const periodUntil: number = insurance.period?.until ?? 0;
    return !loanPeriodInMonths ||
    loanPeriodInMonths === periodUntil
      ? `${fee} ${EnvironmentService.Environment.getAppDomainConfig().currency} (${periodFrom > 1 ? (I18NGetter().useTimeCommons.NEW_TRANSLATION_FROM_LOWER + ' ' + periodFrom + ' ') : ''}${i18nFormatter(I18NGetter().useMortgageTableElements.NEW_TRANSLATION_TILL_MONTH, {periodUntil: periodUntil,})})`
      : EMPTY_VALUE;
  }
  return EMPTY_VALUE;
};

export const renderInsuranceIncludedInTransitionalInstallment = (
  offer: Offer,
  xSell: MortgageXSellType,
  transitionalInstallmentWithBridgingInsurance: boolean = false
): string => {
  const creditedInsurance: Nullable<InsuranceType> = getCreditedInsurance(offer, xSell);
  if (creditedInsurance) {
    return renderCreditedInsuranceDetails(creditedInsurance);
  }
  if (transitionalInstallmentWithBridgingInsurance && !isInBridgingInsurancePeriod(offer, xSell)) {
    return EMPTY_VALUE;
  }
  return renderInsurance(offer, xSell);
};

export const renderInsuranceIncludedInInitialCosts = (
  offer: Offer,
  xSell: Exclude<MortgageXSellType, 'bridgingInsurance' | 'lowPersonalShareInsurance'>
): string => {
  const insuranceCreditedInInitialCosts: Nullable<CreditedInitialCosts[keyof CreditedInitialCosts]> =
    offer.creditedInitialCosts?.[xSell] ?? null;
  const insuranceInInitialCosts: Nullable<InitialCosts[keyof InitialCosts]> = offer.initialCosts?.[xSell] ?? null;
  if (insuranceCreditedInInitialCosts) {
    const {fee, coveredPeriodDescription,} = insuranceCreditedInInitialCosts;
    return `${I18NGetter().useMortgageTableElements.CREDITED_INITIAL_COSTS} / ${numeralFormat(fee)} ${EnvironmentService.Environment.currentCurrency()} (${I18NGetter().useHeaderContent.PER} ${coveredPeriodDescription?.split(' ')[0]} ${I18NGetter().useTimeCommons.MONTHS_SHORT})`;
  }
  if (insuranceInInitialCosts && insuranceInInitialCosts.fee) {
    const {fee, coveredPeriodDescription,} = insuranceInInitialCosts;
    return `${numeralFormat(fee)} ${EnvironmentService.Environment.currentCurrency()} (${I18NGetter().useHeaderContent.PER} ${coveredPeriodDescription?.split(' ')[0]} ${I18NGetter().useTimeCommons.MONTHS_SHORT})`;
  }
  return EMPTY_VALUE;
};

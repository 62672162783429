import {
  OfferDataTableElementKey,
  OfferDataTableGroupKey,
  OfferTableElement
} from '@/components/calculator/results/offers/OfferTableElement';
import {Cost, Offer, XSellKind} from '@/models/simulation/Simulation';
import {numeralFormat} from '@/filters/StringFilters';
import {SimulationDemand} from '@/models/Demand';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {EMPTY_VALUE, TableElementHeight} from '@/components/calculator/results/tableElements/TableElementsConstants';
import EnvironmentService from '@/env/EnvironmentService';
import AuthService from '@/modules/user/AuthService';
import {ReferenceRateType} from '@/models/simulation/ReferenceRateType';
import {
  renderInsurance,
  renderInsuranceIncludedInTransitionalInstallment
} from '@/components/calculator/results/tableElements/helpers/XSell';
import mortgageCalculatorTour from '@/modules/onboarding/tours/MortgageCalculatorTour';

const currentCurrency = EnvironmentService.Environment.currentCurrency();
export const getNumeralOrEmptyWith = (symbol: string) => (value: number | string | undefined | null) => value !== undefined && value !== null ? `${numeralFormat(value)} ${symbol}` : EMPTY_VALUE;
export const getNumeralOrEmptyWithCurrency = getNumeralOrEmptyWith(currentCurrency);
export const getNumeralOrEmptyWithPercent = getNumeralOrEmptyWith('%');
export const getNumeralOrEmptyWithNothing = getNumeralOrEmptyWith('');
export const renderFirstInstallment = (x: Offer, userInput?: Nullable<SimulationDemand>) =>
  (`${numeralFormat(x.monthlyFees.firstInstallment)} ${currentCurrency}` +
    (x.fxValues ? ` (${x.fxValues.firstInstallment} ${userInput?.currency})` : '') + '&nbsp' + ((x.features.isSafeCredit2Percent || x.features.isHomeStartProgram) ? `(${I18NGetter().useMortgageTableElements.AFTER_SUBSIDY})` : '')
  );

const isReferenceOfTypeEdited = (
  referenceRateType: ReferenceRateType,
  userInput?: Nullable<SimulationDemand>
): boolean => {
  const isReferenceRateEdited: boolean = Boolean(userInput?.additionalRequests.referenceRate);
  const isReferenceRateWibor3mEdited: boolean = Boolean(
    userInput?.additionalRequests.referenceRateWibor3m && referenceRateType === ReferenceRateType.WIBOR_3M
  );
  const isReferenceRateWibor6mEdited: boolean = Boolean(
    userInput?.additionalRequests.referenceRateWibor6m && referenceRateType === ReferenceRateType.WIBOR_6M
  );
  const isReferenceRateWiron1mEdited: boolean = Boolean(
    userInput?.additionalRequests.referenceRateWiron1m && referenceRateType === ReferenceRateType.WIRON_1M
  );
  return isReferenceRateEdited ||
    isReferenceRateWibor3mEdited || isReferenceRateWibor6mEdited || isReferenceRateWiron1mEdited;
};

export const CreditWorthiness: OfferTableElement = {
  header: () => I18NGetter().useCommonTableElements.CREDITABILITY,
  key: OfferDataTableElementKey.CREDITABILITY,
  open: false,
  render: (x: Offer) => x.creditWorthiness?.maxCreditAmount
    ? `${numeralFormat(x.creditWorthiness?.maxCreditAmount)} ${EnvironmentService.Environment.currentCurrency()}`
    : I18NGetter().useCommonTableElements.VERIFY,
  children: [
    {
      header: () => '',
      key: OfferDataTableElementKey.CREDITABILITY_DESCRIPTION,
      class: 'justify-start align-start d-block overflow-y-auto',
      height: TableElementHeight.MEDIUM,
      runtimeTemplate: true,
      render: () => {
        return `
        <div class="pa-2">
        <p class="mb-0 font-weight-medium">${I18NGetter().useCommonTableElements.INCOME_TAKEN_INTO_ACCOUNT}</p>
          <div v-for="(x, i) in offer.borrowersInfo.acceptedIncomes" :key="i">
            <template v-if="x.mainIncome">
              <p class="mb-0">${I18NGetter().useCommonTableElements.PERSON_NUMBER} {{x.id.borrower}}</p>
              <p class="mb-0">${I18NGetter().useCommonTableElements.INCOME}: {{x.mainIncome.incomeTypeId}}</p>
              <p class="mb-1">{{x.mainIncome.description || ''}}</p>
            </template>
            <template v-if="x.additionalIncome">
              <p class="mb-0">${I18NGetter().useCommonTableElements.PERSON_NUMBER} {{x.id.borrower}}</p>
              <p class="mb-0">${I18NGetter().useCommonTableElements.INCOME}: {{x.additionalIncome.incomeTypeId}}</p>
              <p class="mb-1">{{x.additionalIncome.description || ''}}</p>
            </template>
          </div>
        </div>`;
      },
    },
  ],
};

const referenceRateLabel = (referenceRateType: ReferenceRateType): string =>
  I18NGetter().ReferenceRateType[referenceRateType];

export const MarginRow: OfferTableElement = {
  header: () => I18NGetter().useMortgageTableElements.HEADER_MARGIN,
  height: TableElementHeight.DOUBLE_INPUT,
  key: OfferDataTableElementKey.MARGIN,
  groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
  open: false,
  printHeight: TableElementHeight.DEFAULT,
  printRender: (x: Offer) => x.changingMargin ? `<div class="d-flex flex-column align-center pa-2">
        <span>${I18NGetter().useMortgageTableElements.FOR} ${x.changingMargin?.initialPeriod} ${I18NGetter().useMortgageTableElements.MONTHS}: ${x.changingMargin.initialValue || 0} %</span>
        <span>${I18NGetter().useMortgageTableElements.AFTER} ${x.changingMargin?.initialPeriod} ${I18NGetter().useMortgageTableElements.MONTHS}: ${x.changingMargin.eventualValue || 0} %</span>
      </div>` : `${x.interestRate.margin || 0} %`,
  runtimeTemplate: true,
  render: () => `
    <div>
    <div v-if="offer.changingMargin" class="d-flex flex-column">
          <div class="d-inline-flex">
            <v-flex class="d-flex align-center mr-2 font-weight-medium">
              ${I18NGetter().useHeaderContent.FOR} {{offer.changingMargin.initialPeriod}} ${I18NGetter().useHeaderContent.MCY}:
            </v-flex>
            <fp-input
            :clearable="false"
            class="percent-input-long"
            @blur="onMarginChange"
            type="number"
            v-model="offer.changingMargin.initialValue"
            suffix="%"
            dense/>
           </div>
           <div class="d-inline-flex mt-2">
            <v-flex class="d-flex align-center mr-2 font-weight-medium">
              ${I18NGetter().useHeaderContent.AFTER} {{offer.changingMargin.initialPeriod}} ${I18NGetter().useHeaderContent.MCU}:
            </v-flex>
            <fp-input
            :clearable="false"
            class="percent-input-long"
            @blur="onEventualMarginChange"
            type="number"
            v-model="offer.changingMargin.eventualValue"
            suffix="%"
            dense/>
          </div>
        </div>
      <fp-input
        v-else
        :clearable="false"
        class="percent-input-long"
        @blur="onMarginChange"
        type="number"
        v-model="offer.interestRate.margin"
        suffix="%"
        dense/>
      </div>
    `,
  children: [
    {
      header: () => '',
      key: OfferDataTableElementKey.MARGIN_DESCRIPTION,
      height: TableElementHeight.MEDIUM,
      class: 'd-block text-wrap overflow-y-auto',
      runtimeTemplate: true,
      render: () => '<fp-markdown class="pa-2 text-left font-weight-regular print-font-size-10 print-font-size-10 caption" :source="offer.interestRate.marginDescription"></fp-markdown>',
      printRender: () => '<fp-markdown v-if="!isAdditionalInfoHidden" class="pa-2 text-left font-weight-regular print-font-size-10 caption" :source="offer.interestRate.marginDescription"></fp-markdown>',
    },
  ],
};

export const InterestRateSum: OfferTableElement = {
  header: () => I18NGetter().useCommonTableElements.INTEREST_RATE_SUM,
  key: OfferDataTableElementKey.INTEREST_RATE_SUM,
  groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
  open: false,
  height: TableElementHeight.DOUBLE_DEFAULT,
  runtimeTemplate: true,
  render: () => `
      <p  v-if="!offer.changingMargin" class="ma-0">{{offer.interestRate.sum}} %</p>
      <p v-if="offer.changingMargin && !offer.changingMargin.initialValueStatic" class="ma-0">${I18NGetter().useTimeCommons.FOR} {{offer.changingMargin.initialPeriod}} ${I18NGetter().useTimeCommons.MONTHS_SHORT}: {{parseFloat((offer.changingMargin.initialValue + offer.interestRate.referenceRate).toFixed(5))}} % </p>
      <p v-if="offer.changingMargin && offer.changingMargin.initialValueStatic" class="ma-0">${I18NGetter().useTimeCommons.FOR} {{offer.changingMargin.initialPeriod}} ${I18NGetter().useTimeCommons.MONTHS_SHORT}: {{(offer.changingMargin.initialValue)}} % {{offer.features.isSafeCredit2Percent ? $i18n.useMortgageTableElements.AFTER_SUBSIDY : ''}}</p>
      <p v-if="offer.changingMargin && !offer.changingMargin.eventualValueStatic" class="ma-0">${I18NGetter().useTimeCommons.AFTER} {{offer.changingMargin.initialPeriod}} ${I18NGetter().useTimeCommons.AFTER_MONTH_SHORT}: {{parseFloat((offer.changingMargin.eventualValue + offer.interestRate.referenceRate).toFixed(5))}} %</p>
      <p v-if="offer.changingMargin && offer.changingMargin.eventualStatic" class="ma-0">${I18NGetter().useTimeCommons.AFTER} {{offer.changingMargin.initialPeriod}} ${I18NGetter().useTimeCommons.AFTER_MONTH_SHORT}: {{(offer.changingMargin.eventualValue)}} %</p>
  `,
  children: [
    {
      header: () => '',
      key: OfferDataTableElementKey.INTEREST_RATE_SUM_DESCRIPTION,
      height: TableElementHeight.LARGE,
      class: 'd-block text-wrap overflow-y-auto body-2',
      runtimeTemplate: true,
      render: (x: Offer, userInput?: Nullable<SimulationDemand>) => {
        return `
        <div class="pa-2 caption print-font-size-10">
          <fp-markdown v-if="store.isCash && !print && offer.interestRate.marginDescription" class="text-left font-weight-regular" :source="offer.interestRate.marginDescription"></fp-markdown>
          <div class="text-left font-weight-regular">
            <template v-if="offer.interestRate?.sum !== offer.interestRate?.margin || store.isMortgage">
              <p class="font-weight-medium mb-0" :class="{'text-center': print}">${referenceRateLabel(x.interestRate.referenceRateType)}: ${x.interestRate.referenceRate} %</p>
              <p v-if="!print" class="mb-0">${I18NGetter().useCommonTableElements.PRESENTED_OFFER} ${isReferenceOfTypeEdited(x.interestRate.referenceRateType, userInput) ? I18NGetter().useCommonTableElements.REFERENCE_RATE_TRUE : I18NGetter().useCommonTableElements.REFERENCE_RATE_FALSE}</p>
            </template>
            <p v-else class="font-weight-medium mb-0" :class="{'text-center': print}">${I18NGetter().useCommonTableElements.PRESENTED_OFFER_CASH}</p>
          </div>
        </div>`;
      },
    },
  ],
};

export const Provision: OfferTableElement = {
  header: () => I18NGetter().useCommonTableElements.HEADER_COMMISSION,
  height: TableElementHeight.DOUBLE_INPUT,
  groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
  key: OfferDataTableElementKey.PROVISION,
  printHeight: TableElementHeight.DEFAULT,
  printRender: (x: Offer) => {
    const getProvisionPercentage = (): number =>
      x.initialCosts.provision.percentage + (x.initialCosts.brokerCommission ?? 0);
    const provisionCost: number = getProvisionPercentage() * x.totals.capital / 100;
    return `${x.creditedCosts.includes(Cost.PROVISION) ? I18NGetter().useCommonTableElements.CREDITED + ' / ' : ''} ${numeralFormat(provisionCost) + EnvironmentService.Environment.currentCurrency()}`;
  },
  runtimeTemplate: true,
  render: (x: Offer) => `
      <div class="d-flex flex-column">
        <div class="d-inline-flex">
          <v-flex class="d-flex align-center justify-end mr-2 font-weight-medium">
              ${x.creditedCosts.includes(Cost.PROVISION) ? I18NGetter().useCommonTableElements.CREDITED : (numeralFormat(x.initialCosts.provision.cost) + EnvironmentService.Environment.currentCurrency())} /
          </v-flex>
          <fp-input
            :clearable="false"
            @input="onProvisionPercentageChange"
            class="percent-input"
            v-model="offer.initialCosts.provision.percentage"
            type="number"
            suffix="%"
            dense/>
        </div>
        <div class="d-flex body-2">
          <span v-if="offer.initialCosts.provision.minPercentage">${I18NGetter().useCommonTableElements.MIN} {{offer.initialCosts.provision.minPercentage}} %</span>
          <span v-if="offer.initialCosts.provision.maxPercentage && offer.initialCosts.provision.maxPercentage !== 100" class="ml-auto">${I18NGetter().useCommonTableElements.MAX} {{offer.initialCosts.provision.maxPercentage}} %</span>
        </div>
        <div v-if="offer.hasBrokerCommission" class="d-inline-flex mt-2">
          <v-flex class="d-flex align-center justify-end mr-2 body-2">
                ${I18NGetter().useCommonTableElements.OVER_COMMISSION}:
          </v-flex>
          <fp-input
            :clearable="false"
            @input="onBrokerCommissionChange"
            class="percent-input"
            v-model="offer.initialCosts.brokerCommission"
            type="number"
            suffix="%"
            dense/>
            <v-tooltip bottom max-width="350px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text icon small class="align-self-center mr-n7">
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </template>
            <span>${I18NGetter().useCommonTableElements.OVER_COMMISSION_TOOLTIP}</span>
          </v-tooltip>
        </div>
      </div>
    `,
};

export const getInsuranceElement = (
  key: OfferDataTableElementKey,
  header: () => string,
  kind: XSellKind,
  groupKey?: OfferDataTableGroupKey,
  condition?: (userInput: Nullable<SimulationDemand>, isPrintMode?: boolean) => boolean,
): OfferTableElement => ({
  key,
  header,
  groupKey,
  height: TableElementHeight.EXTRA_LARGE,
  printHeight: TableElementHeight.LARGE,
  class: 'd-block text-wrap overflow-y-auto',
  runtimeTemplate: true,
  condition,
  render: () => `
        <div class="pa-1 insurances">
          <x-sell-variants-radio
              :offer="offer"
              kind="${kind}"/>
        </div>
        `,
  printRender: () => `
        <div class="pa-1 insurances">
          <x-sell-variants-radio
              :print="true"
              :isAdditionalInfoHidden="store.isMortgage && isAdditionalInfoHidden"
              :offer="offer"
              kind="${kind}"/>
        </div>
        `,
});

export const LoanPeriod: OfferTableElement = {
  header: () => I18NGetter().useCommonTableElements.HEADER_LOAN_PERIOD,
  height: TableElementHeight.DOUBLE_INPUT,
  printHeight: TableElementHeight.DEFAULT,
  key: OfferDataTableElementKey.LOAN_PERIOD,
  groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
  printRender: (x: Offer, userInput?: Nullable<SimulationDemand>) => `${numeralFormat(x.customizations?.customLoanPeriodInMonths || userInput?.loanPeriodInMonths || userInput?.loanPeriod || 0)} ${I18NGetter().useCommonTableElements.MONTHS}`,
  runtimeTemplate: true,
  render: () => `
              <div class="d-flex flex-column">
                    <fp-input
                    class="loan-period-input"
                    type="number"
                    @input="onCustomPeriodChange"
                    :value="customLoanPeriod"
                    suffix="${I18NGetter().useCommonTableElements.MONTHS}"
                    int
                    validate-on-blur
                    dense>
                    </fp-input>
                    <div v-if="offer.restrictions?.loanPeriodRestrictions" class="d-flex justify-space-between body-2">
                        <span>${I18NGetter().useCommonTableElements.MIN} {{offer.restrictions.loanPeriodRestrictions.minLoanPeriod}}</span>
                        <span>${I18NGetter().useCommonTableElements.MAX} {{offer.restrictions.loanPeriodRestrictions.maxLoanPeriod}}</span>
                    </div>
              </div>`,
};

export const LoanAmount: OfferTableElement = {
  header: () => I18NGetter().useCommonTableElements.HEADER_LOAN_AMOUNT,
  height: TableElementHeight.INPUT,
  printHeight: TableElementHeight.DEFAULT,
  key: OfferDataTableElementKey.LOAN_AMOUNT,
  groupKey: OfferDataTableGroupKey.BASIC_PARAMETERS_GROUP,
  printRender: (x: Offer, userInput?: Nullable<SimulationDemand>) => `${numeralFormat(x.customizations?.customLoanAmount || userInput?.loanAmount || 0)} ${EnvironmentService.Environment.currentCurrency()}`,
  runtimeTemplate: true,
  render: () => `
              <div>
                  <fp-input
                    suffix="${EnvironmentService.Environment.currentCurrency()}"
                    :value="customLoanAmount"
                    type="number"
                    int
                    @input="onCustomAmountChange"
                    class="loan-amount-input"
                    dense/>
              </div>
`,
};
